const React = require('react');
const { useContext } = require('react');
const { arrayOf, shape, string, func } = require('prop-types');
const classNames = require('classnames');
const { Action, Label } = require('../../prop-types');
const OtherSellersItem = require('./other-sellers-item');
const componentEnhance = require('../../lib/component-enhance');
const { trackEvent } = require('../../lib/tracking');
const StaticPropsContext = require('../context/static-props');
const { PriceFontSize } = require('../../lib/num-font-size-enum');

const namespace = 'ui-pdp-other-sellers';

const OtherSellers = ({ action: { label, target, track }, className, items, parentUrl, title, showAddToCartModal }) => {
  const { lowEnd, deviceType } = useContext(StaticPropsContext);
  const collapsable = deviceType === 'mobile' && !lowEnd;
  return (
    <div className={classNames(namespace, className)}>
      <h2 className={`${namespace}__title`} data-testid="title">
        {title.text}
      </h2>
      <ul data-testid="items">
        {items.map(item => (
          <OtherSellersItem
            key={`other-seller-item-${item.id}`}
            {...item}
            collapsable={collapsable}
            parentUrl={parentUrl}
            showAddToCartModal={showAddToCartModal}
          />
        ))}
      </ul>
      <a
        href={target}
        onClick={() => {
          trackEvent(track);
        }}
        className={`${namespace}__link`}
        data-testid="link"
      >
        {componentEnhance.jsx(label.text, label.values, null, PriceFontSize.OTHER_SELLERS.LINK)}
      </a>
    </div>
  );
};

OtherSellers.propTypes = {
  action: Action.isRequired,
  className: string,
  items: arrayOf(shape(OtherSellersItem.propsTypes)).isRequired,
  parentUrl: string.isRequired,
  title: Label.isRequired,
  showAddToCartModal: func,
};

OtherSellers.defaultProps = {
  className: null,
  showAddToCartModal: null,
};

module.exports = OtherSellers;
