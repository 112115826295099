const React = require('react');
const PropTypes = require('prop-types');
const classnames = require('classnames');
const Media = require('../media');

const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-payment';

const { arrayOf, string, shape, func, oneOfType, node, number } = PropTypes;

const Payment = ({ action, children, className, icon, subtitles, title }) => (
  <Media
    figure={icon && IconFactory(icon)}
    className={classnames(namespace, className)}
    title={title}
    subtitles={subtitles}
    action={action}
  >
    {children}
  </Media>
);

Payment.propTypes = {
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  children: node,
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: oneOfType([string, node]).isRequired,
};

Payment.defaultProps = {
  action: null,
  children: null,
  className: null,
  subtitles: null,
  icon: null,
};

module.exports = React.memo(Payment);
