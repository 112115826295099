const React = require('react');
const { node, string, shape } = require('prop-types');
const getDisplayName = require('../../lib/get-display-name');
const CardTooltipWrapper = require('./card-tooltip-wrapper');

const withCardTooltip = (WrappedComponent, className) => {
  const withCardTooltipComponent = ({ card_tooltip, ...props }) => (
    <>
      <WrappedComponent {...props} />
      <CardTooltipWrapper cardTooltip={card_tooltip} className={className} />
    </>
  );

  withCardTooltipComponent.propTypes = {
    card_tooltip: shape({}),
  };

  withCardTooltipComponent.defaultProps = {
    card_tooltip: null,
  };

  withCardTooltipComponent.displayName = `withCardTooltipComponent(${getDisplayName(WrappedComponent)})`;

  return withCardTooltipComponent;
};

withCardTooltip.propTypes = {
  WrappedComponent: node.isRequired,
  className: string,
};

withCardTooltip.defaultProps = {
  className: null,
};

module.exports = withCardTooltip;
