const React = require('react');
const { string, arrayOf, any, shape, bool } = require('prop-types');
const classnames = require('classnames');
const Image = require('nordic/image');
const StyledLabel = require('../../styled-label');
const componentEnhance = require('../../../lib/component-enhance');
const TooltipWrapper = require('../../tooltip/tooltip-wrapper');
const availableComponents = require('../../tooltip/tooltip-available-components');
const IconFactory = require('../../icons/factory-builder')();

const namespace = 'ui-pdp-seller__header';

const SellerStore = ({
  className,
  titleValue,
  logo,
  sellerName,
  subtitles,
  showLabelDto,
  tooltip,
  brandTitle,
  icon,
  iconOnTitle,
}) => (
  <TooltipWrapper tooltip={tooltip ? { ...tooltip, availableComponents } : null}>
    <div className={classnames(namespace, className)}>
      {logo && (
        <div className={`${namespace}__image-container`}>
          <Image
            className={`${namespace}__image-container__image`}
            src={logo}
            key={sellerName}
            alt={sellerName}
            lazyload="on"
          />
        </div>
      )}
      <div className={`${namespace}__info-container`}>
        <div className={`${namespace}__info-container__title`}>
          {brandTitle && (
            <StyledLabel
              className={`${namespace}__title-label`}
              as="span"
              font_family={brandTitle.font_family}
              font_size={brandTitle.font_size}
              color={brandTitle.color}
              text={componentEnhance.jsx(brandTitle.text || brandTitle, brandTitle.values)}
            />
          )}
          {showLabelDto ? (
            <StyledLabel
              className={`${namespace}__title-label`}
              as="h3"
              font_family={titleValue.font_family}
              font_size={titleValue.font_size}
              color={titleValue.color}
              text={componentEnhance.jsx(titleValue.text || titleValue, titleValue.values)}
            >
              {iconOnTitle && icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
            </StyledLabel>
          ) : (
            <div className={`${namespace}__title`}>
              {titleValue} {iconOnTitle && icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
            </div>
          )}
        </div>
        <div
          className={
            Array.isArray(subtitles) && subtitles.length === 1 && `${namespace}__info-container__subtitle-one-line`
          }
        >
          {!iconOnTitle && icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
          {subtitles &&
            subtitles.length > 0 &&
            subtitles.map(({ text, values, ...rest }) => {
              if (values && values.font) {
                values.font.className = `${namespace}__subtitle-separator`;
              }
              return (
                <StyledLabel
                  className={`${namespace}__subtitle`}
                  key={text}
                  {...rest}
                  text={componentEnhance.jsx(text, values)}
                />
              );
            })}
        </div>
      </div>
    </div>
  </TooltipWrapper>
);

SellerStore.propTypes = {
  logo: string,
  className: string,
  icon: string,
  sellerName: string.isRequired,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      font_family: string,
      font_size: string,
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  titleValue: any.isRequired,
  showLabelDto: bool,
  tooltip: shape({}),
  brandTitle: shape({
    font_family: string,
    color: string,
    font_size: string,
    text: string,
  }),
  iconOnTitle: bool,
};

SellerStore.defaultProps = {
  className: null,
  logo: null,
  subtitles: null,
  showLabelDto: false,
  tooltip: null,
  brandTitle: null,
  iconOnTitle: false,
};

module.exports = SellerStore;
