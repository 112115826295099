const React = require('react');
const { arrayOf, string, shape } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const Payment = require('../../payment');

const componentEnhance = require('../../../lib/component-enhance');
const onIframeModalResize = require('../../../lib/on-iframe-modal-resize');
const getActionModal = require('../../../lib/get-action-modal');
const PaymentSprites = require('./payment-sprites');

const namespace = 'ui-pdp-payment--md';

const PaymentSummary = ({ action, className, icons, styles, title: { color, text, values }, ...rest }) => (
  <Payment
    action={
      action
        ? getActionModal(
            action,
            onIframeModalResize('vip:modal-content:loaded', 'height', '.ui-pdp-iframe-modal', '.andes-modal__header'),
            false,
          )
        : null
    }
    className={classnames(namespace, className, colornames(color))}
    title={componentEnhance.jsx(text, values)}
    {...rest}
  >
    {styles && icons && icons.length > 0 && <PaymentSprites icons={icons} styles={styles} />}
  </Payment>
);
PaymentSummary.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string.isRequired,
  }),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  icons: arrayOf(
    shape({
      id: string.isRequired,
      size: string,
    }),
  ),
  styles: shape({
    sprite_css: string.isRequired,
    picture_css: string.isRequired,
  }),
  text: string,
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
  }).isRequired,
  closeModalLabel: string,
};

PaymentSummary.defaultProps = {
  action: null,
  className: null,
  icon: null,
  icons: null,
  styles: null,
  text: null,
  closeModalLabel: null,
};

module.exports = PaymentSummary;
