/* eslint-disable react-hooks/rules-of-hooks */
const { useState } = require('react');
const useInterval = require('../hooks/use-interval');

const REMAINING = 'REMAINING';
const ONE_MINUTE = 'ONE_MINUTE';
const LESS_THAN_ONE_HOUR = 'LESS_THAN_ONE_HOUR';
const BTW_ONE_AND_TWO_HOURS_WITHOUT_MIN = 'BTW_ONE_AND_TWO_HOURS_WITHOUT_MIN';
const BTW_ONE_AND_TWO_HOURS_WITH_MIN = 'BTW_ONE_AND_TWO_HOURS_WITH_MIN';
const MORE_THAN_TWO_HOURS_WITHOUT_MIN = 'MORE_THAN_TWO_HOURS_WITHOUT_MIN';
const MORE_THAN_TWO_HOURS_WITH_MIN = 'MORE_THAN_TWO_HOURS_WITH_MIN';

const replaceTimeValues = (label, hours, minutes) => {
  if (label.values.hours) {
    label.values.hours.text = `${hours} h`;
  }
  if (label.values.mins) {
    label.values.mins.text = `${minutes} min`;
  }
  return label;
};

const adaptSubtitlesToRemainingTime = (labels, hours, minutes) => {
  let id = '';
  if (hours >= 2) {
    id = minutes > 0 ? MORE_THAN_TWO_HOURS_WITH_MIN : MORE_THAN_TWO_HOURS_WITHOUT_MIN;
  } else if (hours === 1) {
    id = minutes > 0 ? BTW_ONE_AND_TWO_HOURS_WITH_MIN : BTW_ONE_AND_TWO_HOURS_WITHOUT_MIN;
  } else {
    id = minutes > 1 ? LESS_THAN_ONE_HOUR : ONE_MINUTE;
  }

  return labels.filter(label => label.id === id).map(label => replaceTimeValues(label, hours, minutes));
};

const remainingTimeGetter = remaining => {
  const millisecondsLeft = remaining.initial_time + remaining.total_milliseconds - Date.now();
  const secondsLeft = Math.ceil(millisecondsLeft / 1000);
  const minsLeft = Math.floor((secondsLeft / 60) % 60);
  const hoursLeft = Math.floor(secondsLeft / 60 / 60);

  return { hours: hoursLeft, mins: minsLeft };
};

/**
 * Adapts logic for countdown
 * @param {Object} remaining // Date for remaining
 * @param {int} remaining.hours
 * @param {int} remaining.minutes
 * @param {list} labels // texts from BE
 */
const adaptSubtitlesToRemainingLogic = (remaining, onShippingPromiseUpdate, subtitles, adaptedSubtitles) => {
  let remainingSubtitleIndex = null;

  subtitles.forEach((subtitle, i) => {
    if (subtitle.id === REMAINING) {
      remainingSubtitleIndex = i;
    }
  });

  const [remainingTime, setRemainingTime] = useState({
    hours: remaining.hours,
    mins: remaining.minutes,
  });

  useInterval(clean => {
    setRemainingTime(remainingTimeGetter(remaining));
    if (remainingTime.hours === 0 && remainingTime.mins === 0) {
      onShippingPromiseUpdate();
      clean();
    }
  }, 60000);

  adaptedSubtitles.splice(
    remainingSubtitleIndex,
    1,
    adaptSubtitlesToRemainingTime(remaining.labels, remainingTime.hours, remainingTime.mins)[0],
  );
};

module.exports = adaptSubtitlesToRemainingLogic;
