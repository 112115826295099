const { ActionModal } = require('@vpp-frontend-components/action-modal');

/* 

Por default se exporta una plantilla para usar el componente con toda la lógica de negocio que ya venia arrastrando.
Lo ideal sería que armes tu propia configuración y se la inyectes en el componentes ActionModalUI.
Podes revisarla en el archivo que se encuentra en 'packages/action-modal/src/business-logic/index.js'

Ejemplo:
const ActionModal = withBusinessLogic(ActionModalUI, (props, _methods, context, availableRef) => your_config });


*/

module.exports = ActionModal;
