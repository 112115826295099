const mapJsonToProps = props => {
  const {
    subtitles,
    seller_info,
    title_value,
    title,
    seller,
    show_seller_logo,
    url,
    className,
    seller_link,
    tooltip,
    brand_title,
    close_modal_label,
    components,
    official_store_icon,
    is_exclusive_official_store,
  } = props;

  const {
    extra_info: extraInfo,
    power_seller_status: powerSellerStatus,
    thermometer: thermometerOriginal,
    logo,
    header,
  } = seller_info || {};

  const { default_info: defaultInfo, info, rank } = thermometerOriginal || {};
  const thermometer = { info, defaultInfo, rank };

  const out = {
    titleValue: title_value,
    title,
    subtitles,
    seller: {
      officialStoreId: seller?.official_store_id,
      icon: official_store_icon || seller_info.official_store_icon,
    },
    showSellerLogo: show_seller_logo,
    sellerInfo: {
      extraInfo,
      powerSellerStatus,
      thermometer,
      subtitles: seller_info?.subtitles,
      logo,
      header,
      titleLabel: seller_info?.title_label,
      icon: seller_info?.official_store_icon,
      isExclusiveOfficialStore: is_exclusive_official_store || seller_info?.is_exclusive_official_store,
    },
    thermometer,
    url,
    className,
    seller_link,
    tooltip,
    brandTitle: brand_title,
    closeModalLabel: close_modal_label,
    components,
  };
  return out;
};

module.exports = mapJsonToProps;
