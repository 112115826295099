const { CLOSE_MESSAGE } = require('@vpp-frontend-components/action-modal');
const { trackEvent } = require('@vpp-frontend-components/common');

const getActionModalConfig = (props, _, context) => {
  const { dependencies } = context;
  const { HistoryService } = dependencies;
  return {
    onCloseModal: event => {
      const { modalUrl } = props;

      if (modalUrl && event?.data !== CLOSE_MESSAGE) {
        HistoryService.back();
      }
    },
    onOpenModal: () => {
      const { track } = props;
      trackEvent(track);
    },
  };
};

module.exports = { getActionModalConfig };
