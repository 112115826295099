const {
  getShippingCoachMarkTrack,
  getPromiseTrack,
  getEventClickShippingCalculateTrack,
  getEventClickShippingValuesTrack,
  getCoachTooltipsProps,
  getCoachCardTooltipsProps,
} = require('@vpp-frontend-components/common');

module.exports = {
  getShippingCoachMarkTrack,
  getPromiseTrack,
  getEventClickShippingCalculateTrack,
  getEventClickShippingValuesTrack,
  getCoachTooltipsProps,
  getCoachCardTooltipsProps,
};
