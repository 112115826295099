const React = require('react');
const { string, shape } = require('prop-types');
const classnames = require('classnames');
const { loadable } = require('nordic/lazy');
const { hyphenatedbem } = require('../../lib/hyphenatedbem');

const CardTooltip = loadable(() => import('./index'));

const namespace = 'ui-vpp-card-tooltip';

const CardTooltipComponentWrapper = ({ cardTooltip, className }) => {
  if (!cardTooltip) {
    return null;
  }
  return (
    <CardTooltip
      {...cardTooltip}
      className={classnames(className, namespace, hyphenatedbem(cardTooltip.component_id || cardTooltip.id))}
    />
  );
};

CardTooltipComponentWrapper.propTypes = {
  cardTooltip: shape({}),
  className: string,
};

CardTooltipComponentWrapper.defaultProps = {
  className: null,
  cardTooltip: null,
};

module.exports = CardTooltipComponentWrapper;
