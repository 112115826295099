/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const React = require('react');
const { useState } = require('react');
const { bool, node, shape, string, arrayOf } = require('prop-types');
const colornames = require('../../lib/colornames');
const Tooltip = require('../tooltip');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-legal-tooltip';

const TooltipLegal = ({ children, collapsibleTooltip }) => {
  const { icon, tooltip } = collapsibleTooltip;
  const [visible, setVisible] = useState(false);

  return (
    <Tooltip
      className={namespace}
      title={tooltip.title.text}
      content={tooltip.subtitles[0].text}
      side={tooltip.arrow_type.toLowerCase()}
      trigger="click"
      offsetX={-16}
      closable
      onClose={() => {
        setVisible(false);
      }}
    >
      <div className={`${namespace}__trigger`} onClick={() => setVisible(!visible)}>
        {children}
        {IconFactory(icon.id, colornames(icon.color))}
      </div>
    </Tooltip>
  );
};

TooltipLegal.propTypes = {
  collapsibleTooltip: shape({
    icon: shape({
      id: string,
      color: string,
      size: string,
    }),
    opened: bool,
    tooltip: shape({
      arrow_type: string,
      background_color: string,
      change_color: bool,
      close_color: string,
      closeable: bool,
      closed: bool,
      has_shadow: bool,
      state: string,
      storable: bool,
      subtitles: shape([
        {
          color: string,
          font_family: string,
          font_size: string,
          text: string,
        },
      ]),
      title: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      type: string,
    }),
  }),
  children: node,
  color: string,
  text: string,
  tooltip: shape({
    id: string,
    subtitles: arrayOf(
      shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    ),
  }),
};

TooltipLegal.defaultProps = {
  className: null,
  color: '',
  text: '',
  tooltip: {
    id: '',
    subtitles: [],
  },
  values: {},
  side: 'left',
  trigger: 'click',
};

module.exports = TooltipLegal;
