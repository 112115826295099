const React = require('react');
const { arrayOf, bool, func, shape, string } = require('prop-types');
const classnames = require('classnames');
const Actions = require('../../../../../components/buybox/actions');
const Price = require('../../../../../components/price');
const PaymentSummary = require('../../../../../components/buybox/payment/payment.desktop');
const ShippingSummary = require('../../../../../components/buybox/shipping');
const PickUpSummary = require('../../../../../components/buybox/pick-up');
const SellerInfo = require('../../../../../components/seller-info-label/seller-info.desktop');
const mapSellerToProps = require('../../../../../components/seller-info-label/json-to-props');
const AvailableQuantity = require('../available-quantity/available-quantity.desktop');
const StockInformation = require('../../../../../components/stock-information/stock-information.desktop');
const { PriceFontSize, DiscountFontSize } = require('../../../../../lib/num-font-size-enum');
const CbtTaxesSummary = require('../../../../../components/generic-summary/generic-summary.desktop');
const CbtSummary = require('../../../../../components/generic-summary/generic-summary.desktop');

const Condition = ({ title }) => <span className="ui-pdp-condition ui-pdp-s-condition">{title.text}</span>;

const getPropsForCell = (id, components) => components.find(c => c?.id === id);

const FULL_ICON = 'ui-pdp-full-icon';

const renderComponent = ({
  type,
  components,
  id,
  baseURL,
  onShippingPromiseUpdate,
  isMobile,
  className,
  showAddToCartModal,
}) => {
  const props = getPropsForCell(type, components);

  if (!props || props.state === 'HIDDEN') {
    return null;
  }

  switch (type) {
    case 'price':
      return (
        <Price
          className={className}
          value={props.price.value}
          originalValue={props.price.original_value}
          currencySymbol={props.price.currency_symbol}
          discountLabel={props.discount_label}
          price_tags={props.price_tags}
          discounts_tags={props.discounts_tags}
          subtitles={props.subtitles}
          num_font_size={PriceFontSize.PRICE_RESULT}
          original_value_num_font_size={PriceFontSize.ORIGINAL_PRICE_RESULT}
          discount_num_font_size={DiscountFontSize.PRICE_RESULT}
        />
      );
    case 'payment_summary':
      return <PaymentSummary className={className} {...props} />;
    case 'condition_summary':
      return <Condition {...props} />;
    case 'cbt_summary':
      return <CbtSummary className={className} component_id="cbt_summary" {...props} />;
    case 'cbt_taxes_summary':
      return (
        <CbtTaxesSummary
          className={classnames(className, `cbt-taxes-${props?.title.color}`)}
          component_id="cbt_taxes_summary"
          {...props}
        />
      );
    case 'cbt_taxes_fc_us_summary':
      return (
        <CbtTaxesSummary
          className={classnames(className, props?.title.color ? `cbt-taxes-fc-us-${props.title.color}` : null)}
          component_id="cbt_taxes_fc_us_summary"
          {...props}
        />
      );
    case 'shipping_summary':
      return (
        <ShippingSummary
          onShippingPromiseUpdate={onShippingPromiseUpdate}
          key={Math.random()}
          num_font_size={PriceFontSize.MEDIA_SHIPPING}
          className={classnames(className, { [FULL_ICON]: props?.title?.text_icon?.id === 'FULL_ICON' })}
          {...props}
        />
      );
    case 'pick_up_summary':
      return (
        <PickUpSummary
          onShippingPromiseUpdate={onShippingPromiseUpdate}
          key={Math.random()}
          className={className}
          {...props}
        />
      );
    case 'seller':
      return <SellerInfo {...mapSellerToProps(props)} hashId={id} />;
    case 'available_quantity':
      return <AvailableQuantity {...props} />;
    case 'main_actions':
      return (
        <Actions
          className={className}
          size={isMobile ? 'medium' : 'small'}
          availableQuantity={getPropsForCell('available_quantity', components)}
          {...props}
          form={{ ...props.form, parent_url: baseURL }}
          showAddToCartModal={showAddToCartModal}
        />
      );
    case 'stock_information':
      return <StockInformation className={className} {...props} />;
    default:
      return null;
  }
};

Condition.propTypes = {
  title: shape({
    text: string,
  }).isRequired,
};

renderComponent.propTypes = {
  baseURL: string,
  components: arrayOf(shape()).isRequired,
  discount_label: shape({
    text: string.isRequired,
    color: string,
  }),
  id: string,
  onShippingPromiseUpdate: func,
  showAddToCartModal: func,
  price: shape(),
  state: bool.isRequired,
  type: string.isRequired,
  price_tags: arrayOf(
    shape({
      tags: arrayOf(
        shape({
          text: string,
          color: string,
          bg_color: string,
          font_family: string,
          type: string,
          font_size: 'XSMALL',
        }),
      ).isRequired,
      info: arrayOf(
        shape({
          text: string,
          color: string,
          bg_color: string,
          font_family: string,
          type: string,
          font_size: 'XSMALL',
        }),
      ),
    }),
  ),
  discounts_tags: arrayOf(
    shape({
      tags: arrayOf(
        shape({
          text: string,
          color: string,
          bg_color: string,
          font_family: string,
          type: string,
          font_size: string,
        }),
      ).isRequired,
      info: arrayOf(
        shape({
          text: string,
          color: string,
          bg_color: string,
          font_family: string,
          type: string,
          font_size: string,
        }),
      ),
    }),
  ),
  subtitles: arrayOf(
    shape({
      text: string.isRequired,
      color: string,
      values: shape({}),
    }),
  ),
  form: shape({}),
};

renderComponent.defaultProps = {
  baseURL: null,
  discount_label: null,
  id: null,
  onShippingPromiseUpdate: null,
  showAddToCartModal: null,
  price: null,
  price_tags: null,
  discounts_tags: null,
  subtitles: null,
  form: null,
};

module.exports = renderComponent;
