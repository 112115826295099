const React = require('react');
const { arrayOf, string, shape, bool } = require('prop-types');
const classnames = require('classnames');
const StyledLabel = require('../../styled-label');

const namespace = 'ui-pdp-payment-sprites';

const PaymentSprites = ({ className, icons, styles, iconClassName, lazy }) => (
  <>
    <div className={classnames(namespace, className)}>
      {icons.map(icon => (
        <div key={icon.id} className={`${namespace}__icon-container`}>
          <div
            className={classnames(
              `${namespace}__icon`,
              iconClassName,
              `payments-${icon.size} _${icon.id}-${icon.size}`,
            )}
            data-testid={`payment-sprites-${icon.id}`}
          />
          {icon.label ? (
            <StyledLabel
              as="span"
              className={classnames(`${namespace}__icon-label`, `_${icon.id}-${icon.size}`)}
              {...icon.label}
            />
          ) : null}
        </div>
      ))}
    </div>
    {// eslint-disable-next-line @meli-lint/react/no-style-tags
    styles && !lazy && <style>{Object.values(styles).join(' ')}</style>}
  </>
);

PaymentSprites.propTypes = {
  className: string,
  icons: arrayOf(
    shape({
      id: string.isRequired,
      size: string,
    }),
  ).isRequired,
  styles: shape({
    sprite_css: string.isRequired,
    picture_css: string.isRequired,
  }).isRequired,
  iconClassName: string,
  lazy: bool,
};

PaymentSprites.defaultProps = {
  className: null,
  iconClassName: null,
  lazy: null,
};

module.exports = React.memo(PaymentSprites);
