const React = require('react');
const { arrayOf, shape, string } = require('prop-types');

const IconFactory = require('../../icons/factory-builder')();

const namespace = 'ui-pdp-seller';

const Description = ({ info }) => (
  <div className={`${namespace}__reputation-info`}>
    <ul className={`${namespace}__list-description`}>
      {info.map(item => (
        <li className={`${namespace}__item-description`} key={item.subtitle}>
          {item.icon && (
            <strong aria-hidden="true" className={`${namespace}__icon-description`}>
              {IconFactory(item.icon.id)}
            </strong>
          )}
          {item.title && <strong className={`${namespace}__sales-description`}>{item.title}</strong>}
          {item.subtitle && <p className={`${namespace}__text-description`}>{item.subtitle}</p>}
        </li>
      ))}
    </ul>
  </div>
);

Description.propTypes = {
  info: arrayOf(
    shape({
      icon: shape({
        id: string,
      }),
      subtitlte: string,
      title: string,
    }),
  ).isRequired,
};

module.exports = Description;
