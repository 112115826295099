const React = require('react');
const { arrayOf, shape } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../lib/colornames');
const componentEnhance = require('../../lib/component-enhance');
const IconFactory = require('../icons/factory-builder')();
const { Label } = require('../../prop-types');

const namespace = 'ui-pdp-summary-list';

const SummaryList = ({ list }) => (
  <ul className={namespace}>
    {list.map(item => {
      const { title, state } = item;
      if (state === 'HIDDEN') {
        return null;
      }
      return (
        <li key={title?.text} className={classnames(`${namespace}__item`, colornames(title?.color))}>
          <p className={`${namespace}__text`}>
            {componentEnhance.jsx(title?.text || title, title?.values)}
            <span className={`${namespace}__item-icon`}>{title?.text_icon && IconFactory(title?.text_icon)}</span>
          </p>
        </li>
      );
    })}
  </ul>
);

SummaryList.propTypes = {
  list: arrayOf(
    shape({
      title: Label,
    }),
  ).isRequired,
};

module.exports = SummaryList;
