const {
  isMshops,
  isBuyboxShown,
  getShopFeatures,
  getShopsHeaders,
  sanitizeMshopsHeaders,
} = require('@vpp-frontend-components/common');

module.exports = {
  isMshops,
  isBuyboxShown,
  getShopFeatures,
  getShopsHeaders,
  sanitizeMshopsHeaders,
};
