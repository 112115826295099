const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'chevron';
const namespace = 'ui-pdp-icon ui-pdp-icon--chevron';

const IconChevron = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconChevron.propTypes = {
  className: string,
};

IconChevron.defaultProps = {
  className: null,
};

module.exports = React.memo(IconChevron);
exports = module.exports;
exports.ICON_ID = ICON_ID;
