const React = require('react');
const { arrayOf, bool, string, shape, func, oneOfType, node, number } = require('prop-types');

const classnames = require('classnames');
const Typography = require('@andes/typography');
const MoneyAmount = require('@andes/money-amount');
const ProgressIndicatorCircular = require('@andes/progress-indicator-circular');

const { Remaining } = require('../../prop-types');
const ActionModal = require('../action-modal');
const Media = require('../media');
const colornames = require('../../lib/colornames');
const PaymentSprites = require('../buybox/payment/payment-sprites');
const onIframeModalResize = require('../../lib/on-iframe-modal-resize');
const adaptSubtitlesToRemainingLogic = require('../../utils/adaptSubtitlesToRemainingLogic');
const MediaAction = require('../media/media-action');
const transformActionTarget = require('../../utils/transformActionTarget');
const Iframe = require('../iframe');
const Message = require('../message');
const { setTooltipProps } = require('../tooltip/information-tooltip-props');

const IconFactory = require('../icons/factory-builder')();
const { PriceFontSize } = require('../../lib/num-font-size-enum');

const namespace = 'ui-pdp-generic-summary';

const CbtTaxesSummary = ({ map_content }) => {
  if (!map_content) {
    return null;
  }
  return (
    <div className={`${namespace}__map-content`}>
      {map_content.values.map(value => (
        <>
          <Typography component="span">{value.description}</Typography>
          <Typography>
            {value.value && (
              <MoneyAmount
                value={{ fraction: value.value.fraction_value, cents: value.value.cents }}
                centsType="superscript"
                symbol={value.value.currency_symbol}
                size={16}
              />
            )}
          </Typography>
        </>
      ))}
    </div>
  );
};

CbtTaxesSummary.propTypes = {
  map_content: shape({
    values: arrayOf(
      shape({
        description: string,
        value: shape({
          fraction_value: string,
        }),
        color: string,
      }),
    ),
  }),
};

const getAction = (
  action,
  onIframeMessage,
  component_id,
  action_tooltip,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  meliplusOnDeliveryDayChange,
  noTitle,
) => {
  if (action.html_target === '_blank') {
    return <MediaAction target={action.target} htmlTarget={action.html_target} label={{ text: action.label.text }} />;
  }

  const actionTarget = action.target;
  const lowEndUrl = { ...action, target: actionTarget };
  transformActionTarget(lowEndUrl, { modal: false });

  return (
    <ActionModal
      url={lowEndUrl.target}
      label={action.label}
      modalClassName={classnames(
        `${namespace}-modal ${action.modal_title ? null : 'modal-close-button-desktop'}`,
        'ui-pdp-iframe-modal',
      )}
      modalTitle={noTitle ? false : action.modal_title}
      autoHeight={false}
      icon={action.icon ? IconFactory(action.icon, `${namespace}__action-icon`) : null}
      track={action.track}
      closeTrack={action.close_track}
      closeModalLabel={action.close_modal_label}
      componentId={component_id}
      controlModalHeader
      showCloseButton
      actionTooltip={action_tooltip}
      onCloseModal={onCloseModal}
      meliplusUpsellingOnSuscription={meliplusUpsellingOnSuscription}
      meliplusOnDeliveryDayChange={meliplusOnDeliveryDayChange}
    >
      <Iframe title={action.label?.text} src={actionTarget} onMessage={onIframeMessage} scrolling="no" />
    </ActionModal>
  );
};

const GenericSummary = ({
  action,
  action_tooltip,
  children,
  className,
  icon,
  subtitles,
  title,
  id,
  component_id,
  isFetching,
  stylesSmall,
  tooltip,
  icons,
  updateShippingSummary,
  custom_message,
  shouldShowSpinner,
  remaining,
  blackPriceClassName,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  meliplusOnDeliveryDayChange,
  noTitle,
  map_content,
}) => {
  let adaptedSubtitles = null;

  if (subtitles) {
    adaptedSubtitles = subtitles.slice();
  }

  if (remaining && subtitles) {
    adaptSubtitlesToRemainingLogic(remaining, updateShippingSummary, subtitles, adaptedSubtitles);
  }

  const validateCbtTaxes = () => {
    if (map_content) {
      return null;
    }
    return { ...title, onHover: !!tooltip };
  };

  return (
    <>
      <CbtTaxesSummary map_content={map_content} />
      <Media
        figure={icon && IconFactory(icon)}
        className={classnames(
          namespace,
          `${namespace}--md`,
          className,
          `ui-vpp-${component_id}`,
          colornames(title.color),
          {
            [`${namespace}--remaining`]: remaining,
            [`${namespace}--first-buy`]: !!title?.values?.first_buy,
          },
          { [blackPriceClassName]: blackPriceClassName },
        )}
        title={validateCbtTaxes()}
        showChevron={!!tooltip && tooltip.behaviour !== 'FIXED'}
        subtitles={adaptedSubtitles}
        action={
          action &&
          getAction(
            action,
            onIframeModalResize(
              'vip:modal-content:loaded',
              'height',
              '.ui-pdp-iframe-modal',
              '.andes-modal__header',
              'width',
            ),
            component_id,
            action_tooltip,
            onCloseModal,
            meliplusUpsellingOnSuscription,
            meliplusOnDeliveryDayChange,
            noTitle,
          )
        }
        isFetching={isFetching}
        tooltip={tooltip}
        num_font_size={PriceFontSize.MEDIA_SUBTITLE}
      >
        {children}
        {stylesSmall && icons && icons.length > 0 && <PaymentSprites icons={icons} styles={stylesSmall} />}
        {isFetching && shouldShowSpinner && id !== 'second_shipping_summary' && (
          <ProgressIndicatorCircular modifier="inline" size="medium" className={`${namespace}__spinner`} />
        )}
      </Media>
      {custom_message && (
        <Message
          className={`${namespace}__message`}
          {...{
            closable: custom_message.closable,
            hierarchy: custom_message.hierarchy,
            type: custom_message.message_type,
          }}
        >
          <div className={`${namespace}__message__text`}>{custom_message.body.text}</div>
        </Message>
      )}
    </>
  );
};

GenericSummary.propTypes = {
  blackPriceClassName: string,
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  action_tooltip: shape({
    id: string.isRequired,
    content: string.isRequired,
    props: arrayOf(),
    visible: bool.isRequired,
  }),
  icons: arrayOf(
    shape({
      id: string.isRequired,
      size: string,
    }),
  ),
  stylesSmall: shape({
    sprite_css: string.isRequired,
    picture_css: string.isRequired,
  }),
  children: node,
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  id: string,
  component_id: string,
  remaining: Remaining,
  updateShippingSummary: func,
  isFetching: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  shouldShowSpinner: bool,
  title: shape({}),
  tooltip: shape({}),
  custom_message: shape({
    state: string,
    body: shape({ test: string }),
    closeable: bool,
    hierarchy: string,
    message_type: string,
  }),
  onCloseModal: func,
  meliplusUpsellingOnSuscription: func,
  meliplusOnDeliveryDayChange: func,
  noTitle: bool,
  map_content: shape({
    values: arrayOf(
      shape({
        description: string,
        value: shape({
          fraction_value: string,
        }),
        color: string,
      }),
    ),
  }),
};

GenericSummary.defaultProps = {
  blackPriceClassName: null,
  action: null,
  children: null,
  className: null,
  component_id: null,
  subtitles: null,
  icon: null,
  id: null,
  isFetching: null,
  icons: null,
  stylesSmall: null,
  shouldShowSpinner: false,
  updateShippingSummary: null,
  custom_message: null,
  tooltip: null,
  remaining: null,
  map_content: null,
};

module.exports = React.memo(setTooltipProps(GenericSummary));
