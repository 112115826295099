const React = require('react');
const { number, shape } = require('prop-types');
const { Label } = require('../../../../../prop-types');
const StyledLabel = require('../../../../../components/styled-label');

const namespace = 'ui-pdp-s-available-quantity';

const AvailableQuantity = ({ picker: { selected, title } }) => (
  <>
    <span className={namespace}>
      <StyledLabel as="p" className={`${namespace}__title`} {...title} />
    </span>
    <input type="hidden" name="quantity" value={selected} />
  </>
);

AvailableQuantity.propTypes = {
  picker: shape({
    selected: number.isRequired,
    title: Label.isRequired,
  }).isRequired,
};

module.exports = AvailableQuantity;
