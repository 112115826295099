const React = require('react');
const { arrayOf, number, shape, string, bool } = require('prop-types');
const classnames = require('classnames');
const SellerStore = require('./seller-store');
const Reputation = require('./reputation');

const namespace = 'ui-pdp-seller';

const ContainerInfo = ({
  className,
  extraInfo,
  powerSellerStatus,
  thermometer,
  subtitles,
  logo,
  titleValue,
  officialStoreId,
  brandTitle,
  icon,
  isExclusiveOfficialStore,
}) => (
  <div className={classnames(namespace, className)}>
    <div className={`${namespace}__container-qualification`}>
      {officialStoreId && (
        <SellerStore
          {...{
            brandTitle,
            titleValue,
            logo,
            subtitles,
            sellerName: titleValue,
            icon,
            iconOnTitle: isExclusiveOfficialStore,
          }}
        />
      )}
      {powerSellerStatus && (
        <div className={`${namespace}__seller-status`}>
          <h3 className={`${namespace}__status-title`}>{powerSellerStatus.title}</h3>
          <p className={`${namespace}__status-text`}>{powerSellerStatus.subtitle}</p>
        </div>
      )}
      <Reputation {...{ officialStoreId, thermometer }} />
    </div>
    {!!extraInfo?.length && (
      <ul className={`${namespace}__list-info`}>
        {extraInfo.map(info => (
          <li className={`${namespace}__item-info`} key={info.title}>
            <h3 className={`${namespace}__subtitle-info`}>{info.title}</h3>
            <p className={`${namespace}__text-info`}>{info.subtitle}</p>
          </li>
        ))}
      </ul>
    )}
  </div>
);

ContainerInfo.propTypes = {
  className: string,
  extraInfo: arrayOf(
    shape({
      subtitle: string.isRequired,
      title: string.isRequired,
    }),
  ),
  icon: string,
  isExclusiveOfficialStore: bool,
  logo: string,
  officialStoreId: number,
  powerSellerStatus: shape({ subtitle: string, title: string }),
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      weight: string,
      size: string,
    }),
  ),
  thermometer: shape({
    rank: number,
    info: arrayOf(
      shape({
        icon: shape({ id: string }),
        subtitle: string,
        title: string,
      }),
    ),
    defaultInfo: shape({
      subtitle: shape({
        color: string,
        text: string,
      }),
      title: shape({
        color: string,
        text: string,
      }),
    }),
  }).isRequired,
  titleValue: string.isRequired,
  brandTitle: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }),
};

ContainerInfo.defaultProps = {
  className: null,
  officialStoreId: null,
  powerSellerStatus: null,
  subtitles: null,
  logo: null,
  extraInfo: null,
  brandTitle: null,
};

module.exports = ContainerInfo;
