const React = require('react');
const { func, node, string } = require('prop-types');
const { Form } = require('@andes/form');
const classnames = require('classnames');

const namespace = 'ui-pdp-buybox';

const BuyBox = ({ children, className, onSubmit }) => (
  <Form className={classnames(namespace, className)} id="buybox-form" method="get" onSubmit={onSubmit}>
    {children}
  </Form>
);

BuyBox.propTypes = {
  children: node.isRequired,
  className: string,
  onSubmit: func,
};

BuyBox.defaultProps = {
  className: '',
  onSubmit: null,
};

module.exports = BuyBox;
